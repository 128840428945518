/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from '../ui/image';
import { ImageGallery } from '../ui/imageGallery';
import images from '../../utils/store/images';
import Button from '../ui/button';
import pages from '../../utils/pages';

const { getPageUrl } = pages;
const DemoConversionSection = () => {
  const { t } = useTranslation('general');

  const leftList: any = t('conversion.leftList', {
    returnObjects: true,
  });

  const { shoreSalesPeople } = images;

  return (
    <section className=" section-conversion section-conversion_demo">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-10-desktop conversion-wrapper  conversion-demo-wrapper  is-centered">
            <div className="columns">
              <div className="column ghost-box ">
                <h2 className="title title-large has-text-white">
                  {t('conversion.title')}
                </h2>
                <p className="has-text-white">{t('conversion.text')}</p>
              </div>

              <div className="column web-demo   has-background-white ">
                <h3 className="title title-medium    text-black">
                  {t('conversion.titleLeft')}
                </h3>
                <ul>
                  {Object.keys(leftList).map((key) => {
                    return (
                      <li key={key}>
                        <Image
                          name="check_black.svg"
                          className="inline-svg inline-icon"
                          alt="shore.com"
                        />
                        {leftList[key]}
                      </li>
                    );
                  })}
                </ul>
                <Button
                  size="inline"
                  to={getPageUrl('demo', t('key')).url}
                  color="exclusive"
                >
                  {t('footerDemoButton')}
                </Button>
                <div className="image-container image-list">
                  {Object.keys(shoreSalesPeople).map((key: any) => {
                    return (
                      <div key={key}>
                        <ImageGallery
                          name={shoreSalesPeople[key]}
                          alt="we support you"
                          className="sales_person"
                          maxWidth="70"
                          height="70"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoConversionSection;
