import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobileOnly, isDesktop } from 'react-device-detect';

import Button from '../../../ui/button';
import pages from '../../../../utils/pages';

const { getPageUrl } = pages;

const DemoSection: FC<{
  data: any;
  classes?: string;
}> = ({ data, classes }) => {
  const { t } = useTranslation('general');
  const ssuUrl = getPageUrl('ssu', t('key')).url;
  const demoUrl = getPageUrl('demo', t('key')).url;
  return (
    <section
      className="product_demo_section is-vcentered"
      style={{
        backgroundImage: `url(https://res.cloudinary.com/shore-gmbh/image/upload/w_1558/v1706019037/products/${
          isMobileOnly ? data.imageMobile : data.image
        })`,
      }}
    >
      <div className="container">
        <div className=" columns  ">
          <div className="column is-7 sections-title-left p-0 ">
            <h2 className="title title-large text-white mb-4">{data.title}</h2>
            <p className=" text-white">{data.text}</p>
            <div className="cta_row">
              <Button to={ssuUrl} size="small" primary testid="try-now-btn">
                {data.ssu}
              </Button>
              <Button size="small" color="exclusive" to={demoUrl}>
                {data.demo}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

DemoSection.defaultProps = {
  classes: ``,
};
export default DemoSection;
