/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Slider from 'react-slick';
import { Image } from '../../../ui/image';

import { ImageGallery } from '../../../ui/imageGallery';

import Button from '../../../ui/button';
import pages from '../../../../utils/pages';

const { getPageUrl } = pages;
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`${className} is-hidden-mobile   custom_slick_arrow `}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_right_circle_black"
        alt="arrow next"
        className="arrow arrow-right"
      />
    </span>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={` is-hidden-mobile  custom_slick_arrow ${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_left_circle_black"
        className="arrow arrow-left"
        alt="arrow left"
      />
    </span>
  );
}

const DefaultSlider: FC<{
  classes?: string;
  children?: any;
}> = ({ classes, children }) => {
  const slider = useRef<any>(null);
  const settings = {
    variableWidth: true,
    dots: false,
    autoplay: false,
    infinite: false,
    arrows: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    swipeToSlide: true,
    focusOnSelect: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  function previous(): void {
    if (slider.current) {
      slider.current.slickNext();
    }
  }

  function next(): void {
    slider.current.slickPrev();
  }
  return (
    <>
      <Slider
        ref={(c) => (slider.current = c)}
        {...settings}
        className={`${classes} features_slider solution_slider`}
      >
        {children}
      </Slider>
      <div className="slider_nav is-visible-mobile is-hidden-desktop">
        <div className=" custom_slick_arrow  " onClick={next}>
          <ImageGallery
            name="chevron_left_circle_black"
            className="arrow arrow-left"
            alt="arrow left"
            width={46}
            height={46}
          />
        </div>
        <div className=" custom_slick_arrow " onClick={previous}>
          <ImageGallery
            name="chevron_right_circle_black"
            className="arrow arrow-left"
            alt="arrow left"
          />
        </div>
      </div>
    </>
  );
};
DefaultSlider.defaultProps = {
  classes: ``,
  children: {},
};

const SolutionsSection: FC<{
  data: any;
  classes?: string;
}> = ({ data, classes }) => {
  const { t } = useTranslation('general');

  return (
    <section className="product_solution_section">
      <div className="sections-title columns is-centered">
        <div className="column is-8 ">
          <h2 className="title title-large mx-auto text-centered text-black">
            {data.title}
          </h2>
        </div>
      </div>
      {data.cards && (
        <DefaultSlider classes=" container">
          {Object.keys(data.cards).map((key) => {
            const page = getPageUrl(data.cards[key].ctaKey, t('key'));
            return (
              <div key={key} className=" has-background-white  solution_card">
                <div className="card_inner">
                  <ImageGallery
                    name={data.cards[key].icon}
                    alt={data.cards[key].title}
                    className="solution_image  image-centered "
                    maxWidth="1058"
                    height="458"
                  />
                  <h3 className="text-black text-bold">
                    {data.cards[key].title}
                  </h3>
                  <p>{data.cards[key].text}</p>
                  {data.cards[key].ctaKey && (
                    <Button color="exclusive" link to={page && page.url}>
                      {t('learnMore')}
                      <Image
                        name="angle-right.svg"
                        alt="angle-left icon"
                        className="inline-icon angle inline"
                      />
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </DefaultSlider>
      )}
      {/* slides && (
        <div className="feature_cards is-hidden-desktop is-visible-mobile is-hidden-tablet">
          {Object.keys(slides).map((key, i) => {
            const page = getPageUrl(slides[key].key, 'de');
            return (
              <div key={key} className=" has-background-white featurer_card">
                <ImageGallery
                  name={slides[key].media}
                  alt={slides[key].text.title}
                  className="feature-image image-centered "
                  maxWidth="1058"
                  height="458"
                />
                <h2 className="title title-medium section-title text-black">
                  {slides[key].title}
                </h2>

                <p className="text-medium">{slides[key].text} </p>
                <Button color="exclusive" link to={page && page.url}>
                  {t('learnMore')}
                  <Image
                    name="angle-right.svg"
                    alt="angle-left icon"
                    className="inline-icon angle inline"
                  />
                </Button>
              </div>
            );
          })}
        </div>
      ) */}
    </section>
  );
};

SolutionsSection.defaultProps = {
  classes: ``,
};
export default SolutionsSection;
