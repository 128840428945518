/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import { FC, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeAnimation } from 'react-type-animation';

import { LocalizedPageContext, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { HeroProduct } from '../components/partials/common/hero';
import SolutionsSection from '../components/partials/products/section-solutions';
import BenefitsSection from '../components/partials/products/section-benefits';
import RoiSection from '../components/partials/products/section-roi';
import PricesSection from '../components/partials/products/prices_comparsion';
import ConversionSection from '../components/sections/conversion';
import DemoConversionSection from '../components/sections/conversion_demo';
import DemoSection from '../components/partials/products/section-demo';
import ComparisonSection from '../components/partials/products/section-comparison';
import { Modal } from '../components/partials/common/elements/Modal';

const Features: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { t } = useTranslation(localKey);
  const { lang, alternateUrls } = pageContext;
  const conversionDemo = t('conversionDemo');
  const [index, setIndex] = useState(0);
  const [modal_visibility, toogleModal] = useState(false);
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      toogleModal(false);
    }
  }, []);
  const benefits: any = t('benefitsForYou', {
    returnObjects: true,
  });

  let benefitsClients = false;
  const listBenefitsClients: IColumnList = t('benefitsClients.columns', {
    returnObjects: true,
  });
  if (typeof listBenefitsClients === 'object') benefitsClients = true;

  const roi: any = t('roi', {
    returnObjects: true,
  });

  const solutions: any = t('solutions', {
    returnObjects: true,
  });
  const demo: any = t('demo', {
    returnObjects: true,
  });
  const prices: any = t('prices', {
    returnObjects: true,
  });
  const comparison: any = t('comparison', {
    returnObjects: true,
  });

  const mobileImagesHero: any = t('hero.image-mobile', {
    returnObjects: true,
  });

  let show_shop = false;
  if (localKey === 'merchandise' || localKey === 'accounting') show_shop = true;

  /*   useEffect(() => {
    const intervalDelayMilliseconds = benefits.string_loop[index].length * 500;
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        // reset index if current index is greater than array size
        return prevIndex + 1 < benefits.string_loop.length ? prevIndex + 1 : 0;
      });
    }, intervalDelayMilliseconds);

    return () => clearInterval(interval);
  });
 */
  return (
    <>
      <Layout
        pageContext={pageContext}
        navClasses=""
        mainClasses="feature product"
      >
        <SEO
          lang={lang}
          title={t('meta.title')}
          description={t('meta.description')}
          alternateUrls={alternateUrls}
        />
        <HeroProduct
          translation={t}
          localKey={localKey}
          mobileImagesHero={mobileImagesHero}
          toogleModal={toogleModal}
        />
        <BenefitsSection data={benefits} />
        <RoiSection roi={roi} />
        <SolutionsSection data={solutions} />
        <DemoSection data={demo} />
        <PricesSection data={prices} />
        <ComparisonSection data={comparison} />
        {conversionDemo === 'true' ? (
          <DemoConversionSection />
        ) : (
          <ConversionSection ctaUrl={t('conversion.ctaUrl')} />
        )}
      </Layout>
      <Modal
        classes="video-modal product is-vcentered"
        toogleModal={toogleModal}
        visible={modal_visibility}
      >
        <div className="container">
          <div className=" columns is-centered">
            {localKey === 'booking' ? (
              <video
                className="modal_video"
                controls
                width="450"
                poster="https://res.cloudinary.com/shore-gmbh/image/upload/v1705663882/videos/_thumbnail_website_01_bm.png"
              >
                <source
                  src="https://res.cloudinary.com/shore-gmbh/video/upload/v1705315718/videos/booking.mp4"
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                className="modal_video"
                controls
                poster="https://res.cloudinary.com/shore-gmbh/image/upload/v1705663889/videos/_thumbnail_website_01_pos.png"
              >
                <source
                  src="https://res.cloudinary.com/shore-gmbh/video/upload/v1705316023/videos/pos.mp4"
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Features;
