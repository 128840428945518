/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as React from 'react';
import { FC, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TypeAnimation } from 'react-type-animation';
import CountUp from 'react-countup';
import { isMobileOnly, isDesktop } from 'react-device-detect';

import Slider from 'react-slick';
import { Image } from '../../../ui/image';

import { ImageGallery } from '../../../ui/imageGallery';

import Button from '../../../ui/button';
import pages from '../../../../utils/pages';

const { getPageUrl } = pages;
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`${className}  is-hidden-mobile custom_slick_arrow `}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_right_circle_black"
        alt="arrow next"
        className="arrow arrow-right"
      />
    </span>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className={`custom_slick_arrow  is-hidden-mobile ${className}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <ImageGallery
        name="chevron_left_circle_black"
        className="arrow arrow-left"
        alt="arrow left"
      />
    </span>
  );
}

const DefaultSlider: FC<{
  classes?: string;
  children?: any;
}> = ({ classes, children }) => {
  const slider = useRef<any>(null);

  const settings = {
    variableWidth: true,
    dots: false,
    autoplay: false,
    infinite: false,
    arrows: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  function previous(): void {
    if (slider.current) {
      slider.current.slickNext();
    }
  }

  function next(): void {
    slider.current.slickPrev();
  }
  return (
    <>
      <Slider
        ref={(c) => (slider.current = c)}
        {...settings}
        className={`${classes}  benefits_slider features_slider`}
      >
        {children}
      </Slider>
      <div className="slider_nav is-visible-mobile is-hidden-desktop">
        <div className=" custom_slick_arrow  " onClick={next}>
          <ImageGallery
            name="chevron_left_circle_black"
            className="arrow arrow-left"
            alt="arrow left"
            width={46}
            height={46}
          />
        </div>
        <div className=" custom_slick_arrow " onClick={previous}>
          <ImageGallery
            name="chevron_right_circle_black"
            className="arrow arrow-left"
            alt="arrow left"
          />
        </div>
      </div>
    </>
  );
};
DefaultSlider.defaultProps = {
  classes: ``,
  children: {},
};

const BenefitsSection: FC<{
  data: any;
  classes?: string;
}> = ({ data, classes }) => {
  const { t } = useTranslation('general');
  return (
    <section className="benefits_section">
      <div className="sections-title columns is-centered">
        <div className="column is-8 ">
          <h2 className="title title-large w-100  mx-auto  text-centered text-black">
            {data.title_a}
            <TypeAnimation
              className="looped_string"
              sequence={data.string_typing_loop}
              repeat={Infinity}
            />
            {data.title_b}
          </h2>
        </div>
      </div>

      {data.cards && (
        <DefaultSlider classes=" ">
          {Object.keys(data.cards).map((key) => {
            const [flipped, toogleCard] = useState(false);
            return (
              <div
                key={key}
                className=" has-background-white  featurer_slider_card"
              >
                <div
                  className="flip_box"
                  onKeyDown={() => isMobileOnly || toogleCard(!flipped)}
                  onMouseEnter={() => !isMobileOnly && toogleCard(true)}
                  onMouseLeave={() => !isMobileOnly && toogleCard(false)}
                >
                  <div
                    className={`${
                      classes && classes
                    } SliderInnerCard CardFloatImage  flipCard ${
                      flipped ? 'flipped show-back' : ' show-front'
                    }`}
                  >
                    <div
                      className={`${classes && classes}   card_front   ${
                        flipped
                          ? 'not_flipped hide hidden show-back'
                          : ' show-front'
                      }`}
                      onClick={() => toogleCard(true)}
                    >
                      <Image
                        name="circle_plus_outline.svg"
                        alt="circle_plus_outline-right icon"
                        className="circle_plus_outline-icon angle toogle_icon inline"
                      />
                      <ImageGallery
                        name={data.cards[key].front.image}
                        alt={data.cards[key].title}
                        className="benefit-image image-centered "
                        maxWidth="720 "
                        height="720"
                      />
                    </div>
                    <div
                      className={`${
                        classes && classes
                      }  is-vcentered card_back  flipCard ${
                        flipped ? 'flipped  show-back' : ' show-front hidden'
                      }`}
                      onClick={() => toogleCard(false)}
                    >
                      <Image
                        name="circle_x_outline.svg"
                        alt="circle_plus_outline-right icon"
                        className="circle_x_outline-icon angle toogle_icon inline is-hidden-desktop"
                      />
                      <div className="inner">
                        <h2>
                          <span className="benefit_value">
                            {flipped && (
                              <CountUp
                                decimals={data.cards[key].back.decimal}
                                duration={3.75}
                                separator=""
                                decimal=","
                                suffix={
                                  data.cards[key].back.suffix
                                    ? data.cards[key].back.suffix
                                    : ''
                                }
                                end={data.cards[key].back.value}
                              />
                            )}
                          </span>
                        </h2>
                        <p>{data.cards[key].back.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card_footer">
                  <p className="text-bold text-black">
                    {data.cards[key].title}
                  </p>
                  <p> {data.cards[key].text}</p>
                </div>
              </div>
            );
          })}
        </DefaultSlider>
      )}
    </section>
  );
};

BenefitsSection.defaultProps = {
  classes: ``,
};
export default BenefitsSection;
