import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../../ui/button';
import pages from '../../../../utils/pages';

const { getPageUrl } = pages;

const PricesSection: FC<{
  data: any;
  classes?: string;
}> = ({ data, classes }) => {
  const { t } = useTranslation('general');
  const pricesUrl = getPageUrl('pricing', t('key')).url;
  return (
    <section className="prices_comparsion_section  ">
      <div className="container">
        <div className=" columns   has-text-centered mx-auto is-centered">
          <div className="column is-8 is-12-mobile">
            <h2 className="title title-large text-black   has-text-centered is-centered mx-auto">
              {data.title}
            </h2>
            <Button color="exclusive" className="roi_cta" to={pricesUrl}>
              {data.cta}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

PricesSection.defaultProps = {
  classes: ``,
};
export default PricesSection;
